import $ from 'jquery';
import Mn from 'backbone.marionette';
import Flickity from 'flickity';
import _ from 'underscore';

var Behavior = Mn.Behavior.extend({
    onRender: function (view) {
        this.onAttach(view);
    },
    onViewAttach: function(view){
        this.onAttach(view);
    },
    onAttach: function (view) {
        var self = this;

        setTimeout(function(){
            view.$el.find('[data-carousel]').each(function(){
                self.createCarousel(this);
            })
        },1000)
    },
    onBeforeDestroy: function(){
        var self = this;

        this.$el.find('[data-carousel]').each(function(){
            self.destroyCarousel(this);
        })
    },
    createCarousel: function(el, options){
        return Behavior.createCarousel(el);
    },
    destroyCarousel: function(el){
        return Behavior.destroyCarousel(el);
    }
});

_.extend(Behavior, {
    createCarousel: function(el, options){
        el = $(el);

        if(el.data('carousel.instance')) return;
                
        var data = {
            el: el[0],
            options: _.extend({
                prevNextButtons: false
            }, options, el.data('carousel'))
        };

        el.trigger('initialize.carousel', [data]);

        el.data('carousel.instance', data.instance = new Flickity(data.el, data.options));
        
        el.trigger('afterInitialize.carousel', [data]);
        
        setTimeout(function(){
            try{
                data.instance.resize();
                data.instance.reposition();
            }catch(e){
                return;
            }
        }, 150);
    },
    destroyCarousel: function(el){
        el = $(el);

        if(el.data('carousel.instance')) return;

        el.flickity('destroy');
    }
})

export default Behavior;
